<ng-container *ngIf="useAppWrapper(); else plainTemplate">
<div [ngClass]="[ isPublic ? 'is-public' : '', (appQuery.shouldShowInfoHeader() | async) === false ? 'no-info-header' : '', tenant ]">
    <abi-platform-info-header [networkStatus]="status !== 'ONLINE' ? 'Network: OFFLINE' : ''"></abi-platform-info-header>
    <abi-nav-bar *ngIf="standalone" [sidenav]="sidenav"></abi-nav-bar>
    <mat-sidenav-container class="mat-drawer-transition">
        <!-- NEW SIDEBAR -->
        <mat-sidenav
            *ngIf="!isPublic && hasMenu && useSidebarMenu"
            #menu
            [opened]="menuOpen"
            [mode]="menuMode"
            position="start"
            [autoFocus]="false"
            [ngStyle]="{'max-width': isMobile ? '300px' : '60px'}"
            class="main-menu d-print-none"
            (closedStart)="onMenuClosing()"
        >
            <abi-sidebar [isMobile]="isMobile"></abi-sidebar>
        </mat-sidenav>
        <!-- FOLLOWUPS -->
        <mat-sidenav
            #sidenav
            [opened]="sideNavOpen"
            [mode]="sideNavMode"
            class="px-0 col-xl-2 col-lg-3 col-md-4 col-8 d-print-none"
            position="end"
            [autoFocus]="false"
        >
            <h4 mat-subheader class="m-0">{{ "System.Followups" | translate }}</h4>
            <div class="followups-list d-flex flex-grow-1" >
                <ng-template #followups></ng-template>
            </div>

                <mat-action-list *ngIf="username | async as uName">
                    <button
                        *ngIf="isMobile === false"
                        mat-list-item
                        (click)="pinSideNav()"
                    >
                        <i
                            matListIcon
                            [ngClass]="{
                                'fa-fw text-primary': true,
                                'fas fa-check-square': sideNavMode === 'side',
                                'far fa-square': sideNavMode !== 'side'
                            }"
                        ></i>
                        {{ "System.PinSideBar" | translate }}
                    </button>
                </mat-action-list>
            </mat-sidenav>
            <mat-sidenav-content class="main" [ngStyle]="{ 'margin-left' : '60px' }">
                <div [ngClass]="container" class="main-content d-print-block" role="main">
                    <abi-spinner *ngIf="loading || (langLoaded | async) === false"></abi-spinner>
                    <router-outlet *ngIf="!loading && (langLoaded | async)"> </router-outlet>
                </div>
                <abi-footer *ngIf="standalone"></abi-footer>
            </mat-sidenav-content>
        </mat-sidenav-container>
    </div>
    <keyboard-shortcut-legend title="Keyboard Shortcuts"></keyboard-shortcut-legend>
</ng-container>

<ng-template #plainTemplate>
    <abi-spinner *ngIf="loading"></abi-spinner>
    <router-outlet *ngIf="!loading"> </router-outlet>
</ng-template>

<ng-template #browserCompatibilityWarning>
    <h4 mat-dialog-title>Browser Compatibility Warning</h4>
    <mat-dialog-content>
        <p>Your browser will become unsupported soon.</p>
        <p>You can keep on using the app for now, but we recommend you install or use a more modern one such as <b>Edge</b>, <b>Safari</b>, <b>Firefox</b> or <b>Chrome</b>.</p>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button cdkFocusInitial type="button" mat-button mat-dialog-close>{{ 'System.Ok' | translate }}</button>
    </mat-dialog-actions>
</ng-template>
