import { AfterViewInit, Directive, Inject, Input, OnDestroy, OnInit, Optional } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Observable } from "rxjs";
import { Disposable } from "../";
import { WebLayoutService } from "../services/weblayout.service";
import { BaseFormDirective } from "./base-form";

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class BaseModalForm<TModel> extends Disposable implements OnInit, OnDestroy, AfterViewInit {
  model: TModel;
  itemId: string;
  @Input() inModal = true;// this must be set to false when using a TAG
  // @Input() formErrors: { [control: string]: string[] };
  // _formErrors: { [control: string]: string[] };
  @Input() set formErrors(value: { [control: string]: string[] }) {
    this.baseForm.formErrors = value;
  }
  get formErrors(): { [control: string]: string[] } {
    // other logic
    return this.baseForm.formErrors;
  }


  @Input() group: FormGroup;

  get form(): FormGroup {
    return this.group;
  }

  isRoute = false;
  baseForm: BaseFormDirective;
  matDialogData: TModel;

  constructor(
    webLayoutService: WebLayoutService,
    protected fb: FormBuilder,
    public dialog: MatDialog,
    @Optional() @Inject(MAT_DIALOG_DATA) data?: TModel,// data must be defined if using a modal type container
    @Optional() protected activeModal?: NgbActiveModal,
    @Optional() protected dialogRef?: MatDialogRef<any>,
    @Optional() protected validationMessages: { [control: string]: { [key: string]: string } } = {},
  ) {
    super();
// console.log(validationMessages, 'validationMessages')
    this.baseForm = new BaseFormDirective(webLayoutService, fb, null, null);
    this.matDialogData = data;
  }

  ngOnInit(): void {
    // ROUTE BASED INIT WILL NEED TO REQUEST THE DATA
    if(!this.activeModal && !this.dialogRef) {
      this.isRoute = true;
      this.inModal = false;
      this.group = this.getFormGroup(this.fb);
    }

    // DATA IS ALWAYS PASSED INTO THE MODAL
    if(!this.isRoute && (this.activeModal || this.dialogRef)){
      this.inModal = true;
      this.group = this.getFormGroup(this.fb);
      if(this.matDialogData)
        this.model = this.matDialogData;
    }
  }

  // get formErrors

  abstract getDialogTemplate();

  ngAfterViewInit() {
    this.handleLoading();
  }

  protected handleLoading() {
    if(this.inModal){
      setTimeout(() => {
        if(this.model) {
          this.handleModal(this.group, this.formErrors, this.model);
        }
        if(this.itemId) {
          this.fetchModel(this.itemId).subscribe(model => {
            this.handleModal(this.group, this.formErrors, model);
          });
        }
      }, 1);
    }

    if(this.isRoute){
      setTimeout(() => {
        this.handleRoute();
      }, 1);
    }
  }

  protected fetchModel(itemId: string): Observable<TModel> {
    throw new Error("Not implemented");
  }

  // @deprecated route modal isnt directly supported - modals are for ad-hoc editing, while routes are for dedicated editing (as separate detail screen wrapper)
  isRouteModal = false;
  // self generate modal...
  // @deprecated in favour of applying the- input-section of the modal componet into a separate route based component
  protected handleRouteModal(){
      this.isRouteModal = true;
      // IMPLICIT: THIS WAS TRIGGERED USING THE ROUTER
      // ust get the data/model ID via the param
      this.dialogRef = this.dialog.open(this.getDialogTemplate());
      this.handleModal(this.group, this.formErrors, this.model);
      this.dialogRef.afterClosed().subscribe(value => {
        // this self created dialog needs nothing further
        console.log('going back...');
        history.back();// onyly
      });
  }

  abstract getFormGroup(fb: FormBuilder): FormGroup;
  abstract getFormData(model: TModel, group: FormGroup): TModel | void;
  abstract setFormData(model: TModel, group: FormGroup): void;

  // useful for setting up listeners and advanced stuff on the modal before setting the data
  protected configureModal(model: TModel) {
    this.setFormData(model, this.group);
  }

  handleRoute(): void {
    return null;
  }

  handleModal(group: FormGroup, errors: { [control: string]: string[] }, model?: TModel) {
    this.group = group;
    this.formErrors = errors;
    this.model = model;
    this.configureModal(this.model);

    // Provide Realtime Validation message processing for the form
    this.baseForm.form = this.group;
    this.baseForm.validationMessages = this.validationMessages;
    this.baseForm.ngOnInit();
    // this.formErrors = this.baseForm.formErrors;// formErrors getter currently serves as a passthrough
  }

  closeWithResult(result: any){
    if (this.activeModal) {
      this.activeModal.close(result || this.model);
    } else {
      this.dialogRef.close(result || this.model);
    }
  }

  // Modal Button
  accept() {
    const result = this.accepted();
    this.closeWithResult(result);
  }

  accepted() {
    return this.getFormData(this.model, this.group);
  }

  closeData: any = null;
  cancel() {
    this.cancelled(this.model);
    if (this.activeModal) {
      this.activeModal.dismiss(this.closeData);
    } else {
      this.dialogRef.close(this.closeData);
    }
  }

  // generally nothing is needed here... good for reset/cleanups
  cancelled(model: TModel) {
   // this.setFormData(model, this.group);
  }

  // Form Buttons
  apiBusy = false;
  saveButtonsVisible(): boolean {
    return this.form?.dirty;
  }

  saveAllowed(): boolean {
    return this.saveButtonsVisible() && this.form.enabled && this.form.valid && !this.apiBusy;
  }

  saveIfPossible() {
    if(this.saveAllowed())
    {
      this.saveChanges();
    }
  }

  saveChanges(e?: any): any {
    throw new Error("Not implemented");
  }

  cancelAllowed(): boolean {
    return this.saveButtonsVisible() && this.form.enabled;
  }

  cancelIfPossble() {
    if(this.cancelAllowed())
    {
      this.cancelChanges();
    }
  }

  cancelChanges() {
    throw new Error("Not implemented");
    // this.form.reset();
  }

  currentApiCall: ApiCallType  = null;
  apiCalls: ApiCallType[] = [];
  addApiCall<T>(id: string, prom: () => Promise<T>, andExecute: boolean = true): Promise<T> {
    this.apiBusy = true;
    this.apiCalls.push({
      id,
      status: 'pending',
      message: '',
      call: prom
    });
    // return obs;
    return new Promise((resolve, reject) => {
      if(andExecute)
        this.executeApiCall(id, resolve, reject);
    });
  }


  // Experimental API Call aggregator
  executeApiCall(id: string, resolve: (value?: any) => void, reject: (reason?: any) => void) {
    this.currentApiCall = this.apiCalls.find(c => c.id === id);
    const call = this.currentApiCall;
    if(call){
      call.status = 'executing';
      call.call().then(res => {
        call.status = 'success';
        resolve(res);
      }).catch(e => {
        call.status = 'failed';
        call.message = e;
        reject(e);
      }).finally(() => {
        this.apiBusy = false;
      });
    }
  }

  excuteApiCallById(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.executeApiCall(id, resolve, reject);
    });
  }

  // TODO - read through this stuff...
  executeApiCallsSequentially(): Promise<any> {
    return new Promise((resolve, reject) => {
      const calls = this.apiCalls.filter(c => c.status === 'pending');
      if(calls.length){
        this.executeApiCall(calls[0].id, () => {
          this.executeApiCallsSequentially().then(resolve).catch(reject);
        }, reject);
      } else {
        resolve(null);
      }
    });
  }


  displayCallInfo(apiCall: ApiCallType) {
    return apiCall.status === "executing" ? apiCall.message || apiCall.id : apiCall.status === "failed" ? apiCall.message : "Success";
  }
}
export type ApiCallStatus = 'pending' | 'executing' | 'success' | 'failed';
export interface ApiCallType { id: string, status: ApiCallStatus, message: string, call: () => Promise<any> }
