import { AfterContentChecked, AfterViewChecked, Component, HostBinding, HostListener, Input } from "@angular/core";

@Component({
  selector: "button[abiPrint]",
  templateUrl: "./print.component.html",
  styleUrls: ["./print.component.scss"]
})
export class PrintComponent {
  @Input() class: string;
  @Input() iconClass = "fas fa-print";
  @Input() theme = "primary";
  @HostBinding() type = "button";
  @Input() showDisabled = true;
  @HostBinding("disabled") @Input() disabled: boolean;
  @Input() beforePrint: () => Promise<boolean>;
  @Input("abiPrint") label = "Print";
  @HostBinding("class")
  get buttonClass() {
    return "d-print-none btn btn-" + this.theme + " " + this.class;
  }

  constructor() {}
  @HostListener("click", ["$event.target"])
  print(event: MouseEvent) {
    if (this.beforePrint) {
      this.beforePrint().then(x => {
        // Add a generaous margin to ensure the View has chance to re-render before Print call
        setTimeout(() => {
          this.doPrint();
        }, 1000);
      });
    } else {
      this.doPrint();
    }
  }

  private doPrint() {
    window.print();
  }
}
