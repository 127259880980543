<div
    *ngIf="visible"
    [ngClass]="{ 'form-group': !noFormGroup, row: horizontal }"
    [class]="formFieldClass"
>
    <label
        *ngIf="realLabel || extraLabel"
        [ngClass]="{ 'col-form-label': horizontal }"
        [attr.for]="forId || undefined"
        [class]="labelColClass2"
    >
        {{ realLabel }} {{ extraLabel | translate }}
        <abi-tooltip [tooltip]="tooltip" [noTranslate]="!tooltipTranslate"></abi-tooltip>
    </label>

    <ng-container *ngIf="customComponent; else standardComponent">
        <div *ngIf="horizontal; else customInputSection" [class]="inputColClass">
            <ng-container *ngTemplateOutlet="customInputSection"></ng-container>
        </div>
        <ng-template #customInputSection>
            <ng-template withViewContainerRef> </ng-template>
            <div class="invalid-feedback d-block" *ngIf="errors && errors.length">
                <span *ngFor="let msg of errors" class="d-block">{{
                    msg | translate
                }}</span>
            </div>
        </ng-template>
    </ng-container>

    <ng-template #standardComponent>
        <div *ngIf="horizontal; else inputSection" [class]="inputColClass">
            <ng-container *ngTemplateOutlet="inputSection"></ng-container>
        </div>
    </ng-template>
</div>

<ng-template #inputSection>
    <ng-content></ng-content>
    <div class="invalid-feedback d-block" *ngIf="errors && errors.length">
        <span *ngFor="let msg of errors" class="d-block">{{
            msg | translate
        }}</span>
    </div>
</ng-template>
